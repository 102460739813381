import './App.css';
import { DialogueChoice, DialogueModule, DialogueNode, useDialogueModule } from './twine/useDialogue';
import chapter_1 from './chapters/_CHP1.json'

function App() {

  const {
    currentNode,
    choose
  } = useDialogueModule(chapter_1[0] as DialogueModule);

  return (
    <div className='flex flex-col justify-center items-center min-h-screen'>
      <div className='w-full max-w-2xl rounded border p-4'>
        <DialogueNodeComponent node={currentNode} choose={choose} />
      </div>
    </div>
  );
}


function DialogueNodeComponent(props: { node: DialogueNode, choose: (choice: DialogueChoice) => void}) {

  const link_components = props.node.choices && props.node.choices.map(choice => (
    <ChoiceComponent key={choice.next} choice={choice} choose={props.choose} />
  ));

  return (
    <div>
      <p className='whitespace-pre-wrap'>{props.node.text.ENG}</p>
      <div className='flex flex-row gap-1 py-4'>
        {link_components}
      </div>
    </div>
  );
}

function ChoiceComponent(props: { choice: DialogueChoice, choose: (choice: DialogueChoice) => void}) {
  return (
    <div>
      <button className='text-sky-400 hover:text-sky-600 rounded border-sky-400 border px-2 py-1' onClick={() => props.choose(props.choice)}>{props.choice.text.ENG}</button>
    </div>
  );
}

export default App;

