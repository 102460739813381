import { useState } from "react";


export interface DialogueModule {
  nodes: DialogueNode[],
  variables: {}
}

/*
{
                "character": [
                    "Narrator",
                    1
                ],
                "choices": [
                    {
                        "condition": "",
                        "is_condition": false,
                        "next": "9214027",
                        "text": {
                            "ENG": "Open Phone"
                        }
                    },
                    {
                        "condition": "",
                        "is_condition": false,
                        "next": "6042635",
                        "text": {
                            "ENG": "Ignore It"
                        }
                    }
                ],
                "file": "",
                "is_box": true,
                "node_name": "1570258",
                "node_type": "show_message",
                "object_path": "",
                "slide_camera": true,
                "speaker_type": 0,
                "text": {
                    "ENG": "Its the dawn of a new day, but not a new life. The sound of your phone wakes you from a pleasant misremembered adulteration of long gone school days. Its been 10 years since you were in school and still your brain is trying to comes to terms with it.\n\nYou reach over to smother the miserable melody and for a micro second your eye catches the 10 missed messages from Horton."
                },
                "title": "Show Message"
            },
            */
export interface DialogueNode {
  character: string[],
  choices: DialogueChoice[],
  file: string,
  is_box: boolean,
  node_name: string,
  node_type: string,
  object_path: string,
  slide_camera: boolean,
  speaker_type: number,
  next?: string,
  text: {
    ENG: string,
  },
  title: string
}

export interface DialogueChoice {
  condition: string,
  is_condition: boolean,
  next: string,
  text: {
    ENG: string
  }
}

export function useDialogueModule(module : DialogueModule) {

  const dialogueNodeMap = buildDialogueNodeMap(module);
  const startNode = module.nodes[0];
  const firstNode = dialogueNodeMap.get(startNode.next!) as DialogueNode;
  const [currentNode, setCurrentNode] = useState<DialogueNode>(firstNode)

  const goToNode = (node_name: string) => {
    setCurrentNode(dialogueNodeMap.get(node_name) as DialogueNode);
  }

  const choose = (choice: DialogueChoice) => {
    goToNode(choice.next);
  }

  return {
    currentNode,
    choose
  }
}

function buildDialogueNodeMap(module : DialogueModule) : Map<string, DialogueNode>
{
  const DialogeNodeMap = new Map<string, DialogueNode>();

  module.nodes.forEach(node => DialogeNodeMap.set(node.node_name, node));

  return DialogeNodeMap;
}